$mdc-typography-font-family: unquote('Roboto Slab, Roboto, sans-serif');
@import '@material/typography/mdc-typography';
@import '@material/react-button/index';
@import './palette.sass';

.app {
  align-items: center;
  background: $primary-color;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  min-height: 100vh;
  min-width: 90vw;
  padding: 3rem 0;
}

section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 60%;
  min-height: 100vh;
  min-width: 60%;

  @media all and (max-width: 768px) {
    max-width: 80%;
    min-width: 80%;
  }
}

.section-button {
  align-self: center;
  margin-top: 2rem;
  width: fit-content;
}

ol { 
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  padding: 0;
}

a { color: $primary-color-text;
  text-decoration: none; }

.character {
  display: inline-block;
  position: relative;
  text-align: center;
  vertical-align: text-top;

  &--vowel {
    border-bottom: solid 0.25rem $primary-color-text;
  }

  &--normal {
    opacity: 0.33;
  }
}


.with-audio {
  cursor: pointer;
  outline: none; // :focus
  position: relative;

  &:focus::after {
    opacity: 1;
    top: 100%;
  }

  &::after {
    background-image: url('./sound.svg');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 1.33rem 1.33rem;
    content: ' ';
    display: block;
    height: 2.67rem;
    opacity: 0;
    position: absolute;
    top: 50%;
    transition: all 0.4s ease-in-out;
    width: 100%;
  }
}

.macron-options {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  opacity: 0;
  transition: opacity ease-in 0.3s;
}

.mdc-button.mdc-button--outline {
  @include mdc-typography(button);
  background: transparent !important;
  border-color: $primary-color-light !important;
  color: $primary-color-light !important;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  margin: 1rem 0.5rem;

  &[disabled] {
    opacity: 0.25;
  }
}

.AudioRecorder { /* stylelint-disable-line selector-class-pattern */
  display: inline-block;

  &-button,
  &-download,
  &-remove {
    @extend .mdc-button; /* stylelint-disable-line  scss/at-extend-no-missing-placeholder */
    color: $primary-color-text !important;
    font-family: 'Roboto', sans-serif !important;
    margin-right: 0.5rem;
    margin-top: 0 !important;
  }
}


.segment {
  letter-spacing: 0.25rem;
}

.mdc-button { 
  background: $accent-color !important;
  margin-top: 3rem;
}

@mixin phrase-type {
  @include mdc-typography(headline2);
  color: $primary-color-text;
  display: inline-block;
  list-style: none;
  margin-top: 2rem;
  text-align: center;
}

.phrase {
  @include phrase-type;
}

.word,
.word-divider {
  @include phrase-type;
}

.word.with-audio {
  border-bottom: solid 0.25rem $primary-color-text;
}

.word-divider {
  padding: 0 0.5ch;
}

.phrase {
  margin-top: 2rem;

  &.spread {
    animation-fill-mode: forwards;
    animation-name: speakalong;
    animation-play-state: paused;
    background-image: linear-gradient(to right, transparent 50%, $accent-color 50%);
    background-size: 200%;
    cursor: pointer;
    display: inline;
    line-height: 1.5em;
    padding: 0 1rem;
  }

  &.spread:hover,
  &.spread.animate-start {
    animation-play-state: running;

  }

  // This is as big as the spacing gets
  &.spread--3,
  &[class*='spread--'] {
    animation-duration: 3s;
    letter-spacing: 2rem;
  }

  &.spread--2 {
    animation-duration: 2s;
    letter-spacing: 1rem;
  }

  &.spread--1 {
    animation-duration: 1s;
    letter-spacing: inherit;
  }

  &.spread--0 {
    animation-duration: 0.5s;
    font-style: italic;
    letter-spacing: inherit;
  }
}

@keyframes speakalong {
  from {
    background-position: 0;
  }

  to {
    background-position: -100%;
  }
}

.attribution {
  margin-top: 3rem;
  text-align: center;
}

.word-entry {
  &__word {
    @include mdc-typography(headline1);
    background: transparent;
    border: 0;
    border-bottom: solid 2px $primary-color-light;
    color: $primary-color-text;
    letter-spacing: -1px;
    overflow: hidden;
    padding-bottom: 0.5rem;
    resize: none;
    text-align: center;
  }

  &__word:focus {
    border-bottom-color: $primary-color-text;
    outline: none;
  }

  &__word:focus ~ &__instructions {
    color: $primary-color-text;
  }

  &__word:focus + .macron-options {
    opacity: 1;
  }

  .macron-options > .mdc-button {
    text-transform: initial;
  }

  &__instructions {
    @include mdc-typography(button);
    align-self: center;
    color: $primary-color-light;
    font-family: 'Roboto', sans-serif;
    text-align: center;
  }
}
