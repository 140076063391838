/* Palette generated by Material Palette - materialpalette.com/blue-grey/deep-orange */

$primary-color-dark:   #455A64
$primary-color:        #607D8B
$primary-color-light:  #CFD8DC
$primary-color-text:   #FFFFFF
$accent-color:         #FF5722
$primary-text-color:   #212121
$secondary-text-color: #757575
$divider-color:        #BDBDBD
